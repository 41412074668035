import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = ({ user, setUser }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogout = () => {
    // Логика выхода
    fetch('/api/logout', {
      method: 'POST',
      credentials: 'include',
    })
      .then(() => {
        setUser(null);
      })
      .catch((error) => console.error('Ошибка при выходе:', error));
  };

  return (
    <header className="bg-gray-800 p-4">
      <div className="max-w-screen-xl mx-auto flex justify-between items-center">
        <h1 className="text-3xl font-bold text-white">Simple Gaming</h1>
        <nav className="space-x-8 flex items-center">
          <Link to="/" className="text-lg font-medium text-gray-300 hover:text-gray-100">
            Главная
          </Link>
          <Link to="/store" className="text-lg font-medium text-gray-300 hover:text-gray-100">
            Магазин
          </Link>

          {user ? (
            <div className="relative">
              <button
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="flex items-center space-x-2 bg-gray-800 p-2 rounded-full hover:bg-gray-700 transition-all duration-300"
              >
                <img
                  src={user.avatar_url}
                  alt="Аватар"
                  className="w-8 h-8 rounded-full"
                />
                <span className="text-lg font-medium text-gray-300">{user.name}</span>
              </button>
              {isDropdownOpen && (
                <div className="absolute left-1 mt-2 w-48 bg-gray-800 p-4 rounded-lg shadow-lg">
                  <Link
                    to="/profile"
                    className="w-full text-left text-lg font-semibold text-gray-300 hover:text-gray-100"
                  >
                    Профиль
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="w-full text-left text-lg font-semibold text-gray-300 hover:text-gray-100 mt-2"
                  >
                    Выйти
                  </button>
                </div>
              )}
            </div>
          ) : (
            <a href="/api/auth/steam" className="text-lg font-medium text-gray-300 hover:text-gray-100">
              Войти
            </a>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Header;
