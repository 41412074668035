import React, { useState } from 'react';

const SecurePayPage = () => {
  const [cardNumber, setCardNumber] = useState('');
  const [expireDate, setExpireDate] = useState('');
  const [csc, setCsc] = useState('');
  const [message, setMessage] = useState('');
  const [isValid, setIsValid] = useState(false);

  // Функция для отправки данных карты на сервер
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Проверяем, что expireDate имеет нужный формат
    // if (!/^\d{4}$/.test(expireDate)) {
    //   setMessage('Дата должна быть в формате MMYY');
    //   setIsValid(false);
    //   return;
    // }

    const data = {
      pan: cardNumber,
      expireDate: expireDate, // Убедись, что дата в формате MMYY
      csc: csc,
    };

    try {
      const response = await fetch('https://paymentcard.yoomoney.ru/webservice/storecard/api/storeCardForPayment', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (result.status === 'success') {
        setMessage('Карта прошла проверку успешно!');
        setIsValid(true);
        console.log('Карточка успешно проверена:', result);
      } else {
        setMessage('Ошибка проверки карты.');
        setIsValid(false);
      }
    } catch (error) {
      console.error('Ошибка при отправке запроса:', error);
      setMessage('Произошла ошибка. Попробуйте позже.');
      setIsValid(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white flex flex-col items-center justify-center">
      <h1 className="text-2xl font-bold mb-4">Проверка карты</h1>
      <form
        onSubmit={handleSubmit}
        className="bg-gray-800 p-6 rounded-lg shadow-lg w-96"
      >
        <div className="mb-4">
          <label htmlFor="cardNumber" className="block text-sm">Номер карты</label>
          <input
            type="text"
            id="cardNumber"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            className="w-full mt-2 p-2 bg-gray-700 text-white border border-gray-600 rounded"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="expireDate" className="block text-sm">Дата окончания (MMYY)</label>
          <input
            type="text"
            id="expireDate"
            value={expireDate}
            onChange={(e) => setExpireDate(e.target.value)}
            maxLength="6"
            placeholder="ММГГ"
            className="w-full mt-2 p-2 bg-gray-700 text-white border border-gray-600 rounded"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="csc" className="block text-sm">CVV (код на обратной стороне)</label>
          <input
            type="text"
            id="csc"
            value={csc}
            onChange={(e) => setCsc(e.target.value)}
            className="w-full mt-2 p-2 bg-gray-700 text-white border border-gray-600 rounded"
            required
          />
        </div>

        <div className="mb-4">
          <button
            type="submit"
            className={`w-full p-2 text-white ${isValid ? 'bg-green-600' : 'bg-gray-600'}`}
            disabled={!cardNumber || !expireDate || !csc}
          >
            Проверить карту
          </button>
        </div>

        {message && (
          <div className={`text-sm ${isValid ? 'text-green-400' : 'text-red-400'}`}>
            {message}
          </div>
        )}
      </form>
    </div>
  );
};

export default SecurePayPage;
