// App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import Store from './Store';
import Profile from './Profile';
import SecurePay from './SecurePay';

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    fetch('/api/user', { credentials: 'include' })
      .then((response) => response.json())
      .then((data) => setUser(data))
      .catch((error) => console.error('Ошибка при загрузке данных пользователя:', error));
  }, []);

  return (
    <Router>
      <div className="min-h-screen bg-gray-900 text-white">
        <Header user={user} setUser={setUser} />
        <div className="container mx-auto px-4 py-6">
          <Routes>
            <Route path="/store" element={<Store />} />
            <Route path="/profile" element={<Profile user={user} />} />
            <Route path="/secure-pay/:id" element={<SecurePay user={user} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
