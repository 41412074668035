// Profile.js
import React, { useState } from 'react';
import { FaUser, FaSteam, FaWallet, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Profile = ({ user }) => {

  if (!user) {
    return; // Или можно отображать сообщение о том, что данных нет
  }

  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const formatSteamID = (steamID64) => {
    const parts = steamID64.split('/');
    const steamID = parts[parts.length - 1];
    return `STEAM_1:${steamID % 2}:${Math.floor(steamID / 2)}`;
  };

  const formatBalance = (balance) => {
    return `${balance.toFixed(2)} ₽`;
  };

  const handleDeposit = async () => {
    if (amount <= 0) {
      setError("Сумма должна быть больше нуля");
      return;
    }
    setError('');
    try {
      const response = await fetch('/api/deposit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ amount: parseFloat(amount) })
      });
      if (!response.ok) {
        const errorMsg = await response.text();
        throw new Error(errorMsg || "Ошибка при создании платежа");
      }
      const data = await response.json();
      navigate(`/api/secure-pay/${data.transaction_id}`);
    } catch (err) {
      setError(err.message || "Произошла ошибка");
    }
  };

  return (
    <div className="bg-gray-900 text-white min-h-screen flex items-center justify-center">
      <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-3xl font-bold text-center mb-6">Профиль</h2>
        <div className="space-y-6">
          <div className="flex items-center">
            <FaUser className="text-gray-400 w-6 h-6 mr-4" />
            <div>
              <p className="text-gray-300">Имя</p>
              <p className="text-white font-semibold">{user.name}</p>
            </div>
          </div>
          <div className="flex items-center">
            <FaSteam className="text-gray-400 w-6 h-6 mr-4" />
            <div>
              <p className="text-gray-300">Steam ID</p>
              <p className="text-white font-semibold">{formatSteamID(user.steam_id)}</p>
            </div>
          </div>
          <div className="flex items-center">
            <FaWallet className="text-gray-400 w-6 h-6 mr-4" />
            <div>
              <p className="text-gray-300">Баланс</p>
              <p className="text-white font-semibold">{formatBalance(user.balance)}</p>
            </div>
          </div>
          <div className="mt-6">
            <h3 className="text-xl font-semibold mb-2">Пополнить баланс</h3>
            <input
              type="number"
              min="1"
              step="0.01"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Введите сумму"
              className="w-full p-2 rounded mb-4 text-black"
            />
            <button
              onClick={handleDeposit}
              className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded flex items-center justify-center"
            >
              Пополнить баланс <FaPlus className="ml-2" />
            </button>
            {error && <p className="text-red-500 mt-2">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
