// Store.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const Store = () => {
  const [products, setProducts] = useState([]);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    fetch('/api/products')
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .catch((error) => console.error('Ошибка при загрузке товаров:', error));
  }, []);

  const openProductModal = (product) => {
    setSelectedProduct(product);
    setIsProductModalOpen(true);
  };

  const closeProductModal = () => {
    setIsProductModalOpen(false);
    setSelectedProduct(null);
  };

  return (
    <div className="py-12 px-4">
      <div className="max-w-screen-xl mx-auto px-3">
        <h2 className="text-3xl text-center font-semibold text-gray-200 mb-8">Доступные предметы</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {products.map((product) => (
            <div
              key={product.id}
              className="bg-gray-800 p-6 rounded-lg shadow-lg transition transform hover:scale-105"
            >
              <h3 className="text-xl font-semibold text-gray-100 mb-2">{product.name}</h3>
              <p className="text-gray-400 mb-4">{product.description}</p>
              <p className="text-gray-300 mb-4">Цена: {product.price} ₽</p>
              <button
                onClick={() => openProductModal(product)}
                className="bg-gray-900 text-white py-2 px-6 rounded-md hover:bg-gray-700 transition-all duration-300"
              >
                Купить
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Модальное окно товара */}
      {isProductModalOpen && selectedProduct && (
        <motion.div
          className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <motion.div
            className="bg-gray-900 p-6 rounded-lg w-1/3 space-y-4"
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            exit={{ y: 50 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-2xl font-semibold text-white">{selectedProduct.name}</h3>
            <p className="text-gray-300">{selectedProduct.description}</p>
            {/* Остальные детали продукта */}
            <div className="flex justify-end">
              <button
                onClick={closeProductModal}
                className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 transition-all duration-300"
              >
                Закрыть
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default Store;
